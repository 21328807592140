export function identify() {
  return Promise.resolve()
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export function enableAnalytics() {
  return Promise.resolve() as Promise<void>
}

export function logScreenView(_routeName: string) {
  return Promise.resolve()
}

export function logEvent(_event: string, _params?: Record<string, any>) {
  return Promise.resolve()
}
