import * as Sentry from '@sentry/react'

const isDevelopment =
  (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') ||
  (typeof __DEV__ !== 'undefined' && __DEV__)

import type { ComponentType } from 'react'

import { appVersion } from '@/utils/constants/version'

import * as sentryUtils from './utils'

// eslint-disable-next-line no-console
console.log(`OCHO Web App ${appVersion}\n\nhello[at]ocho.co`)

const PRODUCTION_REPLAYS_SESSION_RATE = 0.05
const DEVELOPMENT_REPLAYS_SESSION_RATE = 0

export const routingInstrumentation = null

Sentry.init({
  ...sentryUtils,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.breadcrumbsIntegration({
      console: false,
      dom: isDevelopment
        ? false
        : {
            // This enables to better identify clicked elements
            serializeAttribute: [
              'data-testid',
              'aria-description',
              'id',
              'class',
            ],
          },
      fetch: !isDevelopment,
      xhr: !isDevelopment,
    }),
    Sentry.replayIntegration({
      blockAllMedia: true,
      maskAllInputs: true,
      maskAllText: false,
      networkCaptureBodies: true,
      networkDetailAllowUrls: [
        /^^https:\/\/env-(prod|dev)\.ocho\.co\/api\/v1\//gi,
      ],
    }),
  ],
  release: `co.ocho.web@${appVersion}`,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: isDevelopment
    ? DEVELOPMENT_REPLAYS_SESSION_RATE
    : PRODUCTION_REPLAYS_SESSION_RATE,
})

export function wrap(Component: ComponentType) {
  return Component
}

export default Sentry
